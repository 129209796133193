


















import * as vr from '@/helpers/validation';
import { ValidationRules } from '@/api/schema';
import { mapState } from 'vuex';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';

export default {
  name: 'PromoCampaignFormRegistration',
  mixins: [PromoCampaignFormMixin],

  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    rules(): ValidationRules {
      return {
        registrationRewardInput: [
          ...this.positiveNumberRules,
          (v) => vr.lte(v, 100000)
        ]
      };
    }
  }
};
